<template>
  <div>
    <b-list-group v-if="runetypes.length > 0">
      <b-list-group-item
        v-for="(item, index) in content"
        :key="'runetypes' + index"
      >
        <b-row>
          <b-col>
            {{ runetype(item.id) }}
          </b-col>
          <b-col sm="auto">
            <b-button
              v-t="'pages.runeTypes.delete'"
              @click="removeRunetype(index)"
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <multiselect
      :placeholder="$t('pages.runeTypes.select')"
      :options="runetypes.map(i => i.id)"
      :custom-label="runetype"
      :preserve-search="false"
      :show-labels="false"
      @select="addRunetype"
    />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "RunetypesSelector",
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      runetypes: []
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    addRunetype(element) {
      this.content.push({ id: element });
      this.$emit("change");
    },
    removeRunetype(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    runetype(id) {
      let runetype = this.runetypes.find(element => element.id == id);
      return runetype.runeType + "/" + runetype.category;
    }
  },
  apollo: {
    runeTypes() {
      return {
        query: gql`
          query runeTypes {
            runeTypes {
              id
              runeType
              category
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }
          var _this = this;

          this.runetypes = result.data.runeTypes.sort((a, b) =>
            a.runeType.localeCompare(b.runeType, "sv")
          );
        }
      };
    }
  }
};
</script>
